import { App, Plugin, Ref, ref, createApp, defineAsyncComponent } from "vue";
import { ElLoading } from "element-plus";

export class LoadingService {
  task = 0;
  isLoading = false;
  loading: any;

  startLoading(): void {
    this.task++;
    this.isLoading = true;
    // if (!this.loading) {
    //   this.loading = ElLoading.service({
    //     lock: true,
    //     text: "Loading",
    //     background: "rgba(0, 0, 0, 0.7)",
    //   });
    // }

    if (!this.loading) {
      const LoadingComponentInstance = defineAsyncComponent(
        () => import("@/components/LoadingComponent.vue")
      );
      const loadingContainer = document.createElement("div");

      this.loading = createApp(LoadingComponentInstance).mount(
        loadingContainer
      );
      document.body.appendChild(this.loading.$el);
    }
  }

  endLoading(): void {
    setTimeout(() => {
      this.task--;
      if (this.task <= 0) {
        this.task = 0;
        this.isLoading = false;
        if (this.loading) {
          // this.loading.close();
          this.loading.$el.remove();
          this.loading = null;
        }
      }
    }, 200);
  }
}

declare module "@vue/runtime-core" {
  interface ComponentCustomProperties {
    $loading: LoadingService;
  }
}

const loadingInstance: Ref<LoadingService | null> = ref(null);

function createLoadingPlugin(): Plugin {
  return {
    install: (app: App, options: any = {}) => {
      if (!loadingInstance.value) {
        loadingInstance.value = new LoadingService();
      }
      app.config.globalProperties.$loading = loadingInstance.value;
      app.provide("$loading", loadingInstance.value);
    },
  };
}

export default createLoadingPlugin;
