import { ActionContext } from "vuex";

interface State {
  data: any;
}

const state: State = {
  data: "",
};

const getters = {
  getList: (state: State) => state.data,
};

const actions = {};

const mutations = {
  SET_LIST(state: State, data: Array<any>) {
    state.data = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
