import Vuex, { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

import user from "./modules/user.module";
import gamehub from "./modules/gamehub.module";
import transferauto from "./modules/transferauto.module";
import history from "./modules/history.module";
import report from "./modules/report.module";
import promotions from "./modules/promotions.module";
import accounts from "./modules/accounts.module";
import banks from "./modules/banks.module";
import historylists from "./modules/historylists.module";
import payment from "./modules/payment.module";

export default createStore({
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
    }),
  ],
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    user,
    gamehub,
    transferauto,
    history,
    report,
    promotions,
    accounts,
    banks,
    historylists,
    payment,
  },
});
