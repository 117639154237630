import { ActionContext } from "vuex";

import ApiServices from "@/core/services/Hub";

interface State {
  list: Array<any>;
}

const state: State = {
  list: [],
};

const getters = {
  getList: (state: State) => state.list,
};

const actions = {
  async fetch(context: ActionContext<State, any>, state: State) {
    try {
      const resp = await ApiServices.getGameHub();
      context.commit("SET_LIST", resp);
    } catch (error: any) {
      if (process.env.NODE_ENV === "development") {
        console.log("error", error);
      }
    }
  },
};

const mutations = {
  SET_LIST(state: State, data: Array<any>) {
    state.list = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
