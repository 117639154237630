<template>
  <el-row justify="center" style="height: 100vh">
    <div
      class="body-view position-relative"
      :style="{
        boxShadow: `var(--el-box-shadow-dark)`,
      }"
    >
      <router-view />
    </div>
  </el-row>
</template>

<script lang="ts" setup>
import { ref, watch, inject, onMounted } from "vue";
import Storage from "@/core/config/Storage";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
const { locale } = useI18n();

onMounted(async () => {
  locale.value = Storage.getLang() ?? "th";
});
</script>

<style lang="scss">
.body-main {
  min-height: calc(100vh - var(--height-footer));
  margin-bottom: var(--height-footer);
}
</style>
