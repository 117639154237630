import { $axios } from "./BaseURL";
import Storage from "./Storage";
import notification from "@/core/plugins/notification";

const axiosInstances = [$axios];

function setHeaderFormData() {
  axiosInstances.forEach((axios) => {
    axios.defaults.headers.post["Content-Type"] = "multipart/form-data";
    axios.defaults.headers.post["Accept"] = "application/json";
    axios.defaults.headers.common["r-lang"] = `${Storage.getLang()}`;
    if (Storage.getToken()) {
      axios.defaults.headers.common["Authorization"] = `${Storage.getToken()}`;
    }
  });
}

axiosInstances.forEach((axios) => {
  axios.interceptors.response.use(
    function (response) {
      // console.log(response)
      if (
        response.data.status != "success" &&
        response.data.msg &&
        response.data.msg != "File uploaded successfully"
      ) {
        notification.message(response.data.status, response.data.msg);
      }

      if (response.data.msg.includes("เข้าใหม่ค่ะ")) {
        Storage.clearToken();
        setTimeout(() => window.location.reload(), 1000);
      }

      return response;
    },
    function (error) {
      if (process.env.NODE_ENV === "development") {
        console.log(error);
      }
      if (error.response.data.msg) {
        notification.message("error", error.response.data.msg);
      }

      if (error.response.status === 401) {
        Storage.clearToken();
        window.location.reload();
      }

      return error.response;
      // return Promise.reject(error);
    }
  );
});

const get = (path: any, params: any = undefined) => {
  setHeaderFormData();
  return $axios.get(path, { params });
};

const post = (path: any, data: any = undefined) => {
  setHeaderFormData();
  return $axios.post(path, data);
};

const postUpload = (path: any, data: any) => {
  setHeaderFormData();
  return $axios.post(path, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export { get, post, postUpload };
