import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// Libary
import i18n from "@/core/plugins/vue-i18n";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import "element-plus/theme-chalk/display.css";

import * as ElementPlusIconsVue from "@element-plus/icons-vue";

import { Vue3Lottie } from "vue3-lottie";

// Style
import "@/assets/style/style.scss";
// import "@/assets/style/emoji.scss";

import createLoadingPlugin from "./core/plugins/loading";

/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";

/* import font awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

/* import specific icons */
import {
  faLine,
  faFacebookMessenger,
  faFacebook,
  faTelegram,
  faPagelines,
} from "@fortawesome/free-brands-svg-icons";
import { faUserSecret } from "@fortawesome/free-solid-svg-icons";
library.add(faLine);
library.add(faFacebookMessenger);
library.add(faFacebook);
library.add(faTelegram);
library.add(faUserSecret);
library.add(faPagelines);

import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

const app = createApp(App);
app.use(router);
app.use(store);
app.use(ElementPlus);

app.use(i18n);
app.component("font-awesome-icon", FontAwesomeIcon);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}

// Install the loading plugin
app.use(createLoadingPlugin());
app.component("Vue3Lottie", Vue3Lottie);
app.component("VueDatePicker", VueDatePicker);

declare global {
  interface Number {
    priceFormat(): string;
  }

  interface String {
    dateFormat(): string;
    dateFormatNoTime(): string;
    dateFormatHHmm(): string;
    toDate(): Date | undefined;
    bankName(): string;
  }
}

// Implement the function on Number prototype
Number.prototype.priceFormat = function (): string {
  return Number(this)
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

String.prototype.bankName = function (): string {
  const locale = localStorage.getItem("lang");
  const bankCode = this.toLowerCase();
  switch (bankCode) {
    case "kbank":
      return locale === "en" ? "Kasikorn Thai" : "กสิกรไทย";
    case "bbl":
      return locale === "en" ? "Bangkok" : "กรุงเทพ";
    case "tmb":
      return locale === "en" ? "TMB" : "ทหารไทย";
    case "ktb":
      return locale === "en" ? "Krungthai" : "กรุงไทย";
    case "bay":
      return locale === "en" ? "Bank of Ayudhya" : "กรุงศรี";
    case "scb":
      return locale === "en" ? "SCB" : "ไทยพาณิชย์";
    case "tbank":
      return locale === "en" ? "Thanachart" : "ธนชาติ";
    case "gsb":
      return locale === "en" ? "Government Savings" : "ออมสิน";
    case "baac":
      return locale === "en" ? "BAAC" : "ธ.ก.ส.";
    case "cimb":
      return locale === "en" ? "CIMB Thai" : "ซีไอเอ็มบี";
    case "uob":
      return locale === "en" ? "UOB" : "ยูโอบี";
    case "ghb":
      return locale === "en" ? "Government Housing" : "อาคารสงเคราะห์";
    case "ttb":
      return locale === "en" ? "TMBThanachart" : "ทหารไทยธนชาต";
    case "lhbank":
      return locale === "en" ? "Land and House" : "แลนด์ แอนด์ เฮ้าส์";
    case "kkp":
      return locale === "en" ? "Kiatnakin" : "เกียรตินาคิน";
    case "truewallet":
      return locale === "en" ? "TRUEWALLET" : "ทรูวอลเล็ต";
    case "tisco":
      return locale === "en" ? "TISCO" : "ทิสโก้";
    default:
      return locale === "en" ? "No data found." : "ไม่พบข้อมูล";
  }
};

app.mount("#app");
