/* eslint-disable @typescript-eslint/no-unused-vars */
import { post, get } from "@/core/config/ApiService";

class User {
  async getUserInfo(): Promise<any> {
    const resp = await get("v1/user/personal");
    return resp.data;
  }

  async telegramChat(): Promise<any> {
    const resp = await get("v1/telegram/chat");
    return resp.data;
  }
}
export default new User();
