import { ElNotification, ElMessage } from "element-plus";
class notification {
  notification(status: any, title: any, msg: any) {
    ElNotification({
      title: title,
      message: msg,
      showClose: false,
      type: status,
    });
  }

  message(type: any, message: any) {
    ElMessage({
      type,
      message,
      showClose: true,
    });
  }
}

export default new notification();
