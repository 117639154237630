import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Storage from "../core/config/Storage";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/login",
    name: "login-page",
    component: () => import("@/views/Auth/Login.vue"),
  },
  {
    path: "/register",
    name: "create-account",
    // component: () => import("@/views/Auth/CloseRegister.vue"),
    component: () => import("@/views/Auth/Register.vue"),
  },
  {
    path: "/login/:token",
    name: "login-token-page",
    component: () => import("@/views/Auth/Token.vue"),
  },
  {
    path: "/forget",
    name: "forget-page",
    component: () => import("@/views/Auth/Forget.vue"),
  },
  {
    path: "/getQr",
    name: "getQr-page",
    component: () => import("@/components/GetQR.vue"),
  },
  {
    path: "/",
    name: "layout",
    component: () => import("@/components/Layout.vue"),
    children: [
      {
        path: "/",
        name: "home",
        component: () => import("@/views/HomeView.vue"),
      },
      {
        path: "/promotion",
        name: "promotion-page",
        component: () => import("@/views/Promotion.vue"),
      },
      {
        path: "/gamehub",
        name: "gamehub-page",
        component: () => import("@/views/GameHub.vue"),
      },
      {
        path: "/setting",
        name: "setting-page",
        component: () => import("@/views/Setting.vue"),
      },
      {
        path: "/betlimit-setting",
        name: "betlimit-page",
        component: () => import("@/views/Betlimit.vue"),
      },
      {
        path: "/profile-setting",
        name: "profile-page",
        component: () => import("@/views/Profile.vue"),
      },
      {
        path: "/change-password",
        name: "change-password-page",
        component: () => import("@/views/ChangePassword.vue"),
      },
      {
        path: "/referral",
        name: "referral-page",
        component: () => import("@/views/Referral.vue"),
      },
      {
        path: "/account-setting",
        name: "account-page",
        component: () => import("@/views/Account.vue"),
      },
      {
        path: "/account-add",
        name: "account-add-page",
        component: () => import("@/views/AccountAdd.vue"),
      },
      {
        path: "/language-setting",
        name: "language-page",
        component: () => import("@/views/LanguageSetting.vue"),
      },
      {
        path: "/deposit",
        name: "deposit-page",
        component: () => import("@/views/Deposit.vue"),
      },
      {
        path: "/withdraw",
        name: "withdraw-page",
        component: () => import("@/views/Withdraw.vue"),
      },
      {
        path: "/transfer-history",
        name: "transfer-history-page",
        component: () => import("@/views/TransferHistory.vue"),
      },
      {
        path: "/transfer-history/:id",
        name: "transfer-history-detail-page",
        component: () => import("@/views/TransferHistoryDetail.vue"),
      },
      {
        path: "/report-history",
        name: "report-history-page",
        component: () => import("@/views/ReportHistory.vue"),
      },
      {
        path: "/report-history/:date",
        name: "report-history-date-page",
        component: () => import("@/views/ReportHistoryByDate.vue"),
      },
      {
        path: "/report-history/:date/:game",
        name: "report-history-game-page",
        component: () => import("@/views/ReportHistoryByGame.vue"),
      },
      {
        path: "/reward/:date",
        name: "reward-page",
        component: () => import("@/views/Reward.vue"),
      },
      {
        path: "/loading/:game/:lang/:mode",
        name: "loading-game-page",
        component: () => import("@/components/LoadingGame.vue"),
      },
      {
        path: "/report-history",
        name: "report-history-page",
        component: () => import("@/views/ReportHistory.vue"),
      },
      {
        path: "/event/vietnam",
        name: "vietnam-event-page",
        component: () => import("@/views/Event/VietnamNumber.vue"),
      },
      {
        path: "/event/spinwheel",
        name: "spinwheel-event-page",
        component: () => import("@/views/Event/Spinwheel.vue"),
      },
      {
        path: "/event/giftbox",
        name: "giftbox-event-page",
        component: () => import("@/views/Event/Giftbox.vue"),
      },
      {
        path: "/:pathMath(.*)*",
        name: "not-found",
        component: () => import("@/views/404.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to: any, from: any, next: any) => {
  const isAuthenticated = await Storage.checkToken();
  if (
    to.name !== "login-page" &&
    to.name !== "forget-page" &&
    to.name !== "login-token-page" &&
    to.name !== "create-account" &&
    !isAuthenticated
  ) {
    await Storage.clearToken();
    next({ name: "login-page" });
  } else if (to.name === "login-token-page") {
    next();
  } else if (
    (to.name === "login-page" ||
      to.name === "forget-page" ||
      to.name === "create-account") &&
    isAuthenticated
  ) {
    next({ name: "home" });
  } else {
    window.scrollTo(0, 0);
    next();
  }
});

export default router;
