class Storage {
  async checkToken() {
    let pass = false;
    pass = localStorage.getItem("token") == null ? false : true;
    return pass;
  }

  setToken(token: string) {
    localStorage.setItem("token", token);
  }
  getToken() {
    return localStorage.getItem("token");
  }

  setLang(lang: any | undefined) {
    localStorage.setItem("lang", lang);
  }
  getLang() {
    return localStorage.getItem("lang");
  }

  setAnnouce(value: any | undefined) {
    localStorage.setItem("annouce", value);
  }
  getAnnouce() {
    return localStorage.getItem("annouce");
  }

  setRef(ref: any | undefined) {
    localStorage.setItem("ref", ref);
  }
  getRef() {
    return localStorage.getItem("ref");
  }

  clearToken() {
    localStorage.removeItem("token");
    localStorage.removeItem("annouce");
    sessionStorage.clear();
  }
}
export default new Storage();
