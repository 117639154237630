/* eslint-disable @typescript-eslint/no-unused-vars */
import { post, get } from "@/core/config/ApiService";

class Hub {
  async getGameHub(): Promise<any> {
    const resp = await get("v1/gamehub");
    return resp.data;
  }

  async getCreditGame(payload: any): Promise<any> {
    const resp = await post("v1/gamehub/balance", payload);
    return resp.data;
  }

  async gamehubPlay(
    provider: string,
    lang: string,
    mode: string
  ): Promise<any> {
    const resp = await get(`v1/gamehub/play/${provider}/${lang}/${mode}`);
    console.log(resp.data)
    return resp.data;
  }
}
export default new Hub();
